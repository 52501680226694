import { Button, Typography } from "@mui/material";
import "./styles.css";

const CustomPreview = ({
  title,
  stringfiedData,
  initialData,
  setData,
}: any) => {
  const clearArrays = (setData: any, initialData: any) => {
    setData(initialData);
  };

  return (
    <div className="custom-preview">
      {title && (
        <Typography variant={"h4"} className="custom-preview-title">
          {title}
        </Typography>
      )}
      <div className="custom-preview-data-content">
        <pre id="boundData">{stringfiedData}</pre>
      </div>
      <Button
        className={"clear-btn"}
        onClick={() => clearArrays(setData, initialData)}
        color="primary"
        variant="contained"
      >
        Limpiar Campos
      </Button>
    </div>
  );
};

export default CustomPreview;
