import { Grid } from "@mui/material";

const FormPageTemplate = ({ firstRender, secondRender }: any) => {
  return (
    <div className="form-page">
      <Grid
        container
        justifyContent={"center"}
        spacing={1}
        className="form-page-container"
      >
        <Grid item sm={12} lg={7}>
          {firstRender}
        </Grid>
        <Grid item sm={12} lg={5}>
          {secondRender}
        </Grid>
      </Grid>
    </div>
  );
};

export default FormPageTemplate;
