import { Property } from "./interfaces";

export const grupoServicios: Property = {
    title: "Grupo de Servicios",
    type: "string",
    oneOf: [
        { const: "01", title: "01: Consulta externa" },
        { const: "02", title: "02: Apoyo diagnóstico y complementación terapéutica" },
        { const: "03", title: "03: Internación" },
        { const: "04", title: "04: Quirúrgico" },
        { const: "05", title: "05: Atención inmediata" },
    ],
}