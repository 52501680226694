import { codMunicipioResidencia } from "./commonSchemas/codMunicipioResidencia";
import { codPaisResidencia } from "./commonSchemas/codPaisResidencia";
import { codSexo } from "./commonSchemas/codSexo";
import { codZonaTerritorialResidencia } from "./commonSchemas/codZonaTerritorialResidencia";
import { fechaNacimiento } from "./commonSchemas/fechaNacimiento";
import { incapacidad } from "./commonSchemas/incapacidad";
import { ObjectSchema } from "./commonSchemas/interfaces";
import { numDocumentoIdentificacion } from "./commonSchemas/numDocumentoIdentificacion";
import { tipoDocumentoIdentificacion } from "./commonSchemas/tipoDocumentoIdentificacion";
import { tipoUsuario } from "./commonSchemas/tipoUsuario";
import { servicios } from "./userSchemas/servicesSchema";

export const userSchema: ObjectSchema = {
  type: "object",
  properties: {
    usuarios: {
      title: "Agregar nuevo Usuario",
      type: "array",
      items: {
        type: "object",
        properties: {
          tipoDocumentoIdentificacion,
          numDocumentoIdentificacion,
          tipoUsuario,
          fechaNacimiento,
          codSexo,
          codPaisResidencia,
          codMunicipioResidencia,
          codZonaTerritorialResidencia,
          incapacidad,
          consecutivo: { title: "Consecutivo", type: "integer" },
          codPaisOrigen: { title: "Código de País de Origen", type: "string" },
          servicios,
        },
      },
    },
  },
};
