import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Dashboard from "./pages/dashboard";
import Login from "./pages/login";
import "./App.css";
import "./styles.scss";

const App: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isValidated, setIsValidated] = useState<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loginTime, setLoginTime] = useState<string | null>(null);
  const expirationTime = 24 * 60 * 60 * 1000; // 24 horas en milisegundos

  useEffect(() => {
    const storedIsLoggedIn = localStorage.getItem("isLoggedIn");
    const storedLoginTime = localStorage.getItem("loginTime");

    if (
      storedIsLoggedIn &&
      storedLoginTime &&
      new Date().getTime() - parseInt(storedLoginTime, 10) < expirationTime
    ) {
      setIsLoggedIn(true);
      setLoginTime(storedLoginTime);
    } else {
      setIsLoggedIn(false);
      setLoginTime(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidated]);

  const handleLogin = () => {
    localStorage.setItem("isLoggedIn", "true");
    localStorage.setItem("loginTime", new Date().getTime().toString());
    setIsLoggedIn(true);
    setLoginTime(new Date().getTime().toString());
  };

  const handleLogout = () => {
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("loginTime");
    setIsLoggedIn(false);
    setLoginTime(null);
  };

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            isLoggedIn ? (
              <Navigate to="/dashboard" />
            ) : (
              <Login onLogin={handleLogin} />
            )
          }
        />
        <Route
          path="/dashboard"
          element={
            isLoggedIn ? (
              <Dashboard
                handleLogout={handleLogout}
                isValidated={isValidated}
                setIsValidated={setIsValidated}
              />
            ) : (
              <Navigate to="/" />
            )
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
