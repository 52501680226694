import { Property } from "./interfaces";

export const tipoDiagnosticoPrincipal: Property = {
    title: "Tipo de Diagnóstico Principal",
    type: "string",
    oneOf: [
        { const: "01", title: "01: Impresión diagnóstica" },
        { const: "02", title: "02: Confirmado nuevo" },
        { const: "03", title: "03: Confirmado repetido" },
    ],
}