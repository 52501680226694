import { Property } from "./interfaces";

export const finalidadTecnologiaSalud: Property = {
    title: "Finalidad Tecnología Salud",
    type: "string",
    oneOf: [
        { const: "11", title: "11: Valoración integral para la promoción y mantenimiento" },
        { const: "12", title: "12: Detección temprana de enfermedad general" },
        { const: "13", title: "13: Detección temprana de enfermedad laboral" },
        { const: "14", title: "14: Protección específica" },
        { const: "15", title: "15: Diagnostico" },
        { const: "16", title: "16: Tratamiento" },
        { const: "17", title: "17: Rehabilitación" },
        { const: "18", title: "18: Paliación" },
        { const: "19", title: "19: Planificación familiar y anticoncepción" },
        { const: "20", title: "20: Promoción y apoyo a la lactancia materna" },
        { const: "21", title: "21: Atención básica de orientación familiar" },
        { const: "22", title: "22: Atención para el cuidado preconcepcional" },
        { const: "23", title: "23: Atención para el cuidado prenatal" },
        { const: "24", title: "24: Interrupción voluntaria del embarazo" },
        { const: "25", title: "25: Atención del parto y puerperio" },
        { const: "26", title: "26: Atención para el cuidado del recien nacido" },
        { const: "27", title: "27: Atención para el seguimiento del recien nacido" },
        { const: "28", title: "28: Preparación para la maternidad y la paternidad" },
        { const: "29", title: "29: Promoción de actividad física" },
        { const: "30", title: "30: Promoción de la cesación del tabaquismo" },
        { const: "31", title: "31: Prevención del consumo de sustancias psicoactivas" },
        { const: "32", title: "32: Promoción de la alimentación saludable" },
        { const: "33", title: "33: Promoción para el ejercicio de los derechos sexuales y derechos reproductivos" },
        { const: "34", title: "34: Promoción para el desarrollo de habilidades para la vida" },
        { const: "35", title: "35: Promoción para la construcción de estrategias de afrontamiento frente a sucesos vitales" },
        { const: "36", title: "36: Promoción de la sana convivencia y el tejido social " },
        { const: "37", title: "37: Promoción de un ambiente seguro y de cuidado y protección del ambiente " },
        { const: "38", title: "38: Promoción del empoderamiento para el ejercicio del derecho a la salud" },
        { const: "39", title: "39: Promoción para la adopción de practicas de crianza y cuidado para la salud" },
        { const: "40", title: "40: Promoción para la capacidad de la agencia y cuidado de la salud" },
        { const: "41", title: "41: Desarrollo de habilidades cognitivas" },
        { const: "42", title: "42: Intervención colectiva" },
        { const: "43", title: "43: Modificación de la estetica corporal (Fines estéticos)" },
        { const: "44", title: "44: Otra" },

    ]
}