import { Property } from "./interfaces";

export const codServicio: Property = {
  title: "Código de Servicio",
  type: "integer",
  oneOf: [

    { const: 105, title: "105: CUIDADO INTERMEDIO NEONATAL" },
    { const: 106, title: "106: CUIDADO INTERMEDIO PEDIATRICO" },
    { const: 107, title: "107: CUIDADO INTERMEDIO ADULTOS" },
    { const: 108, title: "108: CUIDADO INTENSIVO NEONATAL" },
    { const: 109, title: "109: CUIDADO INTENSIVO PEDIATRICO" },
    { const: 110, title: "110: CUIDADO INTENSIVO ADULTOS" },
    { const: 1101, title: "1101: ATENCION DEL PARTO" },
    { const: 1102, title: "1102: URGENCIAS" },
    { const: 1103, title: "1103: TRANSPORTE ASISTENCIAL BASICO" },
    { const: 1104, title: "1104: TRANSPORTE ASISTENCIAL MEDICALIZADO" },
    { const: 1105, title: "1105: ATENCION PREHOSPITALARIA" },
    { const: 120, title: "120: CUIDADO BASICO NEONATAL" },
    { const: 129, title: "129: HOSPITALIZACION ADULTOS" },
    { const: 130, title: "130: HOSPITALIZACION PEDIATRICA" },
    { const: 131, title: "131: HOSPITALIZACION EN SALUD MENTAL" },
    { const: 132, title: "132: HOSPITALIZACION PARCIAL" },
    { const: 133, title: "133: HOSPITALIZACION PACIENTE CRONICO CON VENTILADOR" },
    { const: 134, title: "134: HOSPITALIZACION PACIENTE CRONICO SIN VENTILADOR" },
    { const: 135, title: "135: HOSPITALIZACION EN CONSUMO DE SUSTANCIAS PSICOACTIVAS" },
    { const: 138, title: "138: CUIDADO BASICO DEL CONSUMO DE SUSTANCIAS PSICOACTIVAS" },
    { const: 201, title: "201: CIRUGIA DE CABEZA Y CUELLO" },
    { const: 202, title: "202: CIRUGIA CARDIOVASCULAR" },
    { const: 203, title: "203: CIRUGIA GENERAL" },
    { const: 204, title: "204: CIRUGIA GINECOLOGICA" },
    { const: 205, title: "205: CIRUGIA MAXILOFACIAL" },
    { const: 207, title: "207: CIRUGIA ORTOPEDICA" },
    { const: 208, title: "208: CIRUGIA OFTALMOLOGICA" },
    { const: 209, title: "209: CIRUGIA OTORRINOLARINGOLOGIA" },
    { const: 210, title: "210: CIRUGIA ONCOLOGICA" },
    { const: 211, title: "211: CIRUGIA ORAL" },
    { const: 212, title: "212: CIRUGIA PEDIATRICA" },
    { const: 213, title: "213: CIRUGIA PLASTICA Y ESTETICA" },
    { const: 214, title: "214: CIRUGIA VASCULAR Y ANGIOLOGICA" },
    { const: 215, title: "215: CIRUGIA UROLOGICA" },
    { const: 217, title: "217: OTRAS CIRUGIAS" },
    { const: 218, title: "218: CIRUGIA ENDOVASCULAR NEUROLOGICA" },
    { const: 227, title: "227: CIRUGIA ONCOLOGICA PEDIATRICA" },
    { const: 231, title: "231: CIRUGIA DE LA MANO" },
    { const: 232, title: "232: CIRUGIA DE MAMA Y TUMORES TEJIDOS BLANDOS" },
    { const: 233, title: "233: CIRUGIA DERMATOLOGICA" },
    { const: 234, title: "234: CIRUGIA DE TORAX" },
    { const: 235, title: "235: CIRUGIA GASTROINTESTINAL" },
    { const: 237, title: "237: CIRUGIA PLASTICA ONCOLOGICA" },
    { const: 245, title: "245: NEUROCIRUGIA" },
    { const: 301, title: "301: ANESTESIA" },
    { const: 302, title: "302: CARDIOLOGIA" },
    { const: 303, title: "303: CIRUGIA CARDIOVASCULAR" },
    { const: 304, title: "304: CIRUGIA GENERAL" },
    { const: 306, title: "306: CIRUGIA PEDIATRICA" },
    { const: 308, title: "308: DERMATOLOGIA" },
    { const: 309, title: "309: DOLOR Y CUIDADOS PALIATIVOS" },
    { const: 310, title: "310: ENDOCRINOLOGIA" },
    { const: 311, title: "311: ENDODONCIA" },
    { const: 312, title: "312: ENFERMERIA" },
    { const: 313, title: "313: ESTOMATOLOGIA" },
    { const: 316, title: "316: GASTROENTEROLOGIA" },
    { const: 317, title: "317: GENETICA" },
    { const: 318, title: "318: GERIATRIA" },
    { const: 320, title: "320: GINECOBSTETRICIA" },
    { const: 321, title: "321: HEMATOLOGIA" },
    { const: 323, title: "323: INFECTOLOGIA" },
    { const: 324, title: "324: INMUNOLOGIA" },
    { const: 325, title: "325: MEDICINA FAMILIAR" },
    { const: 326, title: "326: MEDICINA FISICA Y DEL DEPORTE" },
    { const: 327, title: "327: MEDICINA FISICA Y REHABILITACION" },
    { const: 328, title: "328: MEDICINA GENERAL" },
    { const: 329, title: "329: MEDICINA INTERNA" },
    { const: 330, title: "330: NEFROLOGIA" },
    { const: 331, title: "331: NEUMOLOGIA" },
    { const: 332, title: "332: NEUROLOGIA" },
    { const: 333, title: "333: NUTRICION Y DIETETICA" },
    { const: 334, title: "334: ODONTOLOGIA GENERAL" },
    { const: 335, title: "335: OFTALMOLOGIA" },
    { const: 336, title: "336: ONCOLOGIA CLINICA" },
    { const: 337, title: "337: OPTOMETRIA" },
    { const: 338, title: "338: ORTODONCIA" },
    { const: 339, title: "339: ORTOPEDIA Y/O TRAUMATOLOGIA" },
    { const: 340, title: "340: OTORRINOLARINGOLOGIA" },
    { const: 342, title: "342: PEDIATRIA" },
    { const: 343, title: "343: PERIODONCIA" },
    { const: 344, title: "344: PSICOLOGIA" },
    { const: 345, title: "345: PSIQUIATRIA" },
    { const: 346, title: "346: REHABILITACION ONCOLOGICA" },
    { const: 347, title: "347: REHABILITACION ORAL" },
    { const: 348, title: "348: REUMATOLOGIA" },
    { const: 354, title: "354: TOXICOLOGIA" },
    { const: 355, title: "355: UROLOGIA" },
    { const: 356, title: "356: OTRAS CONSULTAS DE ESPECIALIDAD" },
    { const: 361, title: "361: CARDIOLOGIA PEDIATRICA" },
    { const: 362, title: "362: CIRUGIA DE CABEZA Y CUELLO" },
    { const: 363, title: "363: CIRUGIA DE MANO" },
    { const: 364, title: "364: CIRUGIA DE MAMA Y TUMORES TEJIDOS BLANDOS" },
    { const: 365, title: "365: CIRUGIA DERMATOLOGICA" },
    { const: 366, title: "366: CIRUGIA DE TORAX" },
    { const: 367, title: "367: CIRUGIA GASTROINTESTINAL" },
    { const: 368, title: "368: CIRUGIA GINECOLOGICA LAPAROSCOPICA" },
    { const: 369, title: "369: CIRUGIA PLASTICA Y ESTETICA" },
    { const: 370, title: "370: CIRUGIA PLASTICA ONCOLOGICA" },
    { const: 371, title: "371: OTRAS CONSULTAS GENERALES" },
    { const: 372, title: "372: CIRUGIA VASCULAR" },
    { const: 373, title: "373: CIRUGIA ONCOLOGICA" },
    { const: 374, title: "374: CIRUGIA ONCOLOGICA PEDIATRICA" },
    { const: 375, title: "375: DERMATOLOGIA ONCOLOGICA" },
    { const: 377, title: "377: COLOPROCTOLOGIA" },
    { const: 379, title: "379: GINECOLOGIA ONCOLOGICA" },
    { const: 383, title: "383: MEDICINA NUCLEAR" },
    { const: 384, title: "384: NEFROLOGIA PEDIATRICA" },
    { const: 385, title: "385: NEONATOLOGIA" },
    { const: 386, title: "386: NEUMOLOGIA PEDIATRICA" },
    { const: 387, title: "387: NEUROCIRUGIA" },
    { const: 388, title: "388: NEUROPEDIATRIA" },
    { const: 390, title: "390: OFTALMOLOGIA ONCOLOGICA" },
    { const: 391, title: "391: ONCOLOGIA Y HEMATOLOGIA PEDIATRICA" },
    { const: 393, title: "393: ORTOPEDIA ONCOLOGICA" },
    { const: 395, title: "395: UROLOGIA ONCOLOGICA" },
    { const: 396, title: "396: ODONTOPEDIATRIA" },
    { const: 397, title: "397: MEDICINA ESTETICA" },
    { const: 406, title: "406: HEMATOLOGIA ONCOLOGICA" },
    { const: 407, title: "407: MEDICINA DEL TRABAJO Y MEDICINA LABORAL" },
    { const: 408, title: "408: RADIOTERAPIA" },
    { const: 409, title: "409: ORTOPEDIA PEDIATRICA" },
    { const: 410, title: "410: CIRUGIA ORAL" },
    { const: 411, title: "411: CIRUGIA MAXILOFACIAL" },
    { const: 412, title: "412: MEDICINA ALTERNATIVA Y COMPLEMENTARIA - HOMEOPATICA" },
    { const: 413, title: "413: MEDICINA ALTERNATIVA Y COMPLEMENTARIA - AYURVEDICA" },
    { const: 414, title: "414: MEDICINA ALTERNATIVA Y COMPLEMENTARIA - TRADICIONAL CHINA" },
    { const: 415, title: "415: MEDICINA ALTERNATIVA Y COMPLEMENTARIA - NATUROPATICA" },
    { const: 416, title: "416: MEDICINA ALTERNATIVA Y COMPLEMENTARIA - NEURALTERAPEUTICA" },
    { const: 417, title: "417: TERAPIAS ALTERNATIVAS Y COMPLEMENTARIAS - BIOENERGETICA" },
    { const: 418, title: "418: TERAPIAS ALTERNATIVAS Y COMPLEMENTARIAS - TERAPIA  CON FILTROS" },
    { const: 419, title: "419: TERAPIAS ALTERNATIVAS Y COMPLEMENTARIAS - TERAPIAS  MANUALES" },
    { const: 420, title: "420: VACUNACION" },
    { const: 421, title: "421: PATOLOGIA" },
    { const: 422, title: "422: MEDICINA ALTERNATIVA Y COMPLEMENTARIA - OSTEOPATICA" },
    { const: 423, title: "423: SEGURIDAD Y SALUD EN EL TRABAJO" },
    { const: 706, title: "706: LABORATORIO CLINICO" },
    { const: 709, title: "709: QUIMIOTERAPIA" },
    { const: 711, title: "711: RADIOTERAPIA" },
    { const: 712, title: "712: TOMA DE MUESTRAS DE LABORATORIO CLINICO" },
    { const: 714, title: "714: SERVICIO FARMACEUTICO" },
    { const: 715, title: "715: MEDICINA NUCLEAR" },
    { const: 717, title: "717: LABORATORIO CITOLOGIAS CERVICO-UTERINAS" },
    { const: 728, title: "728: TERAPIA OCUPACIONAL" },
    { const: 729, title: "729: TERAPIA RESPIRATORIA" },
    { const: 731, title: "731: LABORATORIO DE HISTOTECNOLOGIA" },
    { const: 733, title: "733: HEMODIALISIS" },
    { const: 734, title: "734: DIALISIS PERITONEAL" },
    { const: 739, title: "739: FISIOTERAPIA" },
    { const: 740, title: "740: FONOAUDIOLOGIA Y/O TERAPIA DEL LENGUAJE" },
    { const: 742, title: "742: DIAGNOSTICO VASCULAR" },
    { const: 743, title: "743: HEMODINAMIA E INTERVENCIONISMO" },
    { const: 744, title: "744: IMAGENES DIAGNOSTICAS- IONIZANTES" },
    { const: 745, title: "745: IMAGENES DIAGNOSTICAS - NO IONIZANTES" },
    { const: 746, title: "746: GESTION PRE-TRANSFUSIONAL" },
    { const: 747, title: "747: PATOLOGIA" },
    { const: 748, title: "748: RADIOLOGIA ODONTOLOGICA" },
    { const: 749, title: "749: TOMA DE MUESTRAS DE CUELLO UTERINO Y GINECOLOGICAS" },
  ],
};
