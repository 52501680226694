import { Grid } from "@mui/material";

const FormFullPageTemplate = ({
  firstRender,
  secondRender,
  className,
}: any) => {
  return (
    <div className={`form-page ${className}`}>
      <Grid
        container
        justifyContent={"center"}
        spacing={1}
        className="form-page-container"
      >
        <Grid item sm={12}>
          {firstRender}
        </Grid>
        <Grid item sm={12}>
          {secondRender}
        </Grid>
      </Grid>
    </div>
  );
};

export default FormFullPageTemplate;
