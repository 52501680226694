import { Property } from "./interfaces";

export const incapacidad: Property = {
    title: "Incapacidad",
    type: "string",
    oneOf: [
        {
            const: "SI",
            title: "Sí"
        },
        {
            const: "NO",
            title: "No"
        }
    ]
}