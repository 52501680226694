export const initialTransactionsData: { [key: string]: string | null } = {
  numDocumentoIdObligado: "",
  numFactura: "",
  tipoNota: null,
  numNota: "",
};
export const initialUsersData = { usuarios: [] };
export const initialQueriesData = { consultas: [] };
export const initialProceduresData = { procedimientos: [] };
export const initialEmergenciesData = { urgencias: [] };
export const initialHospitalizationsData = {
  hospitalizacion: [],
};
export const initialNewlyBornData = {
  recienNacidos: [],
};
export const initialMedicinesData = { medicamentos: [] };
export const initialOtherServicesData = { otrosServicios: [] };
