import { Property } from "./interfaces";

export const tipoOS: Property = {
    title: "Tipo de OS",
    type: "string",
    oneOf: [
        { const: "01", title: "01: Dispositivos Medicos e Insumos" },
        { const: "02", title: "02: Traslados" },
        { const: "03", title: "03: Estancias" },
        { const: "04", title: "04: Servicios Complementarios" },
        { const: "05", title: "05: Honorarios" }
    ]
}