import CustomJsonForm from "../../components/organisms/custom-json-form";
import CustomPreview from "../../components/organisms/custom-preview";
import schema from "./schema.json";
import { userSchema } from "./userSchema";
import uiSchema from "./ui-schema.json";
import { PageTypes } from "../../utils/Types";
import FormFullPageTemplate from "../../templates/main-template/form-full-page";
import "./styles.scss";
import { Article, Code } from "@mui/icons-material";
import CustomTabs from "../../components/organisms/custom-tabs";
import { useEffect, useState } from "react";

const UsersPage = ({
  data,
  setData,
  onChange,
  stringfiedData,
  initialData,
}: PageTypes) => {
  const [tabsValue, setTabsValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabsValue(newValue);
  };

  console.log("Normal Schema: ", schema);
  console.log("New Schema:", userSchema);

  const mainTabsSchema = [
    {
      label: "Formulario",
      icon: <Article />,
      iconPosition: "end",
      render: (
        <CustomJsonForm
          schema={userSchema}
          uiSchema={uiSchema}
          data={data}
          onChange={onChange}
        />
      ),
    },
    {
      icon: <Code />,
      iconPosition: "end",
      label: "JSON",
      render: (
        <CustomPreview
          stringfiedData={stringfiedData}
          initialData={initialData}
          setData={setData}
        />
      ),
    },
  ];

  return (
    <FormFullPageTemplate
      className={"users-page"}
      firstRender={
        <CustomTabs
          name={"users"}
          tabsSchema={mainTabsSchema}
          tabsValue={tabsValue}
          handleChange={handleChange}
          ariaLabel="Sección Principal"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          tabsClassName="tabs-right"
        />
      }
    />
  );
};

export default UsersPage;
