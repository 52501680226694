import { Property } from "./interfaces";

export const codZonaTerritorialResidencia: Property = {
  title: "Código de zona territorial de Residencia",
  type: "string",
  oneOf: [
    { const: "01", title: "01: Rural"},
    { const: "02", title: "02: Urbano"},
  ],
};
