import { Property } from "./interfaces";

export const unidadMinDispensa: Property = {
  title: "Unidad Mínima de Dispensa",
  type: "string",
  oneOf: [
    { const: "01", title: "01: AMPOLLA"},
    { const: "10", title: "10: ENVASE"},
    { const: "11", title: "11: CAJA"},
    { const: "12", title: "12: LATA"},
    { const: "13", title: "13: FRASCO"},
    { const: "14", title: "14: CAPSULA"},
    { const: "15", title: "15: CARTON"},
    { const: "16", title: "16: CARTUCHO"},
    { const: "17", title: "17: ESTUCHE"},
    { const: "18", title: "18: PELICULA DE PVC TRANSPARENTE DE COLORES"},
  ]
};
