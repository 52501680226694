import { Property } from "./interfaces";

export const modalidadGrupoServicioTecSal: Property = {
    title: "Modalidad Grupo Servicio Tec Sal",
    type: "string",
    oneOf: [
        { const: "01", title: "01: Intramural" },
        { const: "02", title: "02: Extramural unidad móvil" },
        { const: "03", title: "03: Extramural domiciliaria" },
        { const: "04", title: "04: Extramural jornada de salud" },
        { const: "06", title: "06: Telemedicina interactiva" },
        { const: "07", title: "07: Telemedicina no interactiva" },
        { const: "08", title: "08: Telemedicina telexperticia" },
        { const: "09", title: "09: Telemedicina telemonitoreo" },
    ],
}