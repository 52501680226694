import {
  materialCells,
  materialRenderers,
} from "@jsonforms/material-renderers";
import { JsonForms } from "@jsonforms/react";
import { Typography } from "@mui/material";
import ratingControlTester from "./ratingControlTester";
import RatingControl from "./RatingControl";

const CustomJsonForm = ({
  title,
  schema,
  uiSchema,
  data,
  onChange,
  className = "",
}: any) => {
  const renderers = [
    ...materialRenderers,
    { tester: ratingControlTester, renderer: RatingControl },
  ];

  return (
    <div className={`custom-json-form ${className}`}>
      {title && (
        <Typography variant={"h4"} className="custom-json-form-title">
          {title}
        </Typography>
      )}
      <div className={`form-container json-form`}>
        <JsonForms
          schema={schema}
          uischema={uiSchema}
          data={data}
          renderers={renderers}
          cells={materialCells}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default CustomJsonForm;
