import { useMemo } from "react";
import { convertZero } from "../utils/functions";

export function useStringifiedData(data: any) {
  return useMemo(() => {
    const modifiedData = convertZero(data);

    return JSON.stringify(modifiedData, null, 2);
  }, [data]);
}
