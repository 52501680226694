import { cantidadOS } from "../../commonSchemas/cantidadOS";
import { codPrestador } from "../../commonSchemas/codPrestador";
import { codTecnologiaSalud } from "../../commonSchemas/codTeconologiaSalud";
import { consecutivo } from "../../commonSchemas/consecutivo";
import { fechaSuministroTecnologia } from "../../commonSchemas/fechaSuministroTecnologia";
import { idMIPRES } from "../../commonSchemas/idMIPRES";
import { Property } from "../../commonSchemas/interfaces";
import { nomTecnologiaSalud } from "../../commonSchemas/nomTecnologiaSalud";
import { numeroAutorizacion } from "../../commonSchemas/numeroAutorizacion";
import { numDocumentoIdentificacion } from "../../commonSchemas/numDocumentoIdentificacion";
import { numFEVPagoModerador } from "../../commonSchemas/numFEVPagoModerador";
import { tipoDocumentoIdentificacion } from "../../commonSchemas/tipoDocumentoIdentificacion";
import { tipoOS } from "../../commonSchemas/tipoOS";
import { conceptoRecaudo } from "../../commonSchemas/conceptoRecaudo";
import { valorPagoModerador } from "../../commonSchemas/valorPagoModerador";
import { vrServicio } from "../../commonSchemas/vrServicio";
import { vrUnitOS } from "../../commonSchemas/vrUnitOS";

export const otrosServicios: Property = {
  title: "Agregar otro servicio",
  type: "array",
  items: {
    type: "object",
    properties: {
      codPrestador,
      numAutorizacion: { ...numeroAutorizacion },
      idMIPRES,
      fechaSuministroTecnologia,
      tipoOS,
      codTecnologiaSalud,
      nomTecnologiaSalud,
      cantidadOS,
      tipoDocumentoIdentificacion,
      numDocumentoIdentificacion,
      vrUnitOS,
      vrServicio,
      conceptoRecaudo,
      valorPagoModerador,
      numFEVPagoModerador,
      consecutivo,
    },
  },
};
