import { Property } from "../commonSchemas/interfaces";
import { consultas } from "./services/consultas";
import { hospitalizacion } from "./services/hospitalizacion";
import { medicamentos } from "./services/medicamentos";
import { otrosServicios } from "./services/otrosServicios";
import { procedimientos } from "./services/procedimientos";
import { recienNacidos } from "./services/recienNacidos";
import { urgencias } from "./services/urgencias";

export const servicios: Property = {
  title: "Servicios",
  properties: {
    consultas,
    procedimientos,
    urgencias,
    hospitalizacion,
    recienNacidos,
    medicamentos,
    otrosServicios,
  },
};
