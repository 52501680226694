import { Property } from "./interfaces";

export const condicionDestinoUsuarioEgreso: Property = {
    title: "Condición de Destino del Usuario al Egreso",
    type: "string",
    oneOf: [
        { const: "01", title: "01: Paciente con destino a su domicilio" },
        { const: "02", title: "02: Paciente muerto" },
        { const: "03", title: "03: Paciente derivado a otro servicio" },
        { const: "04", title: "04: Referido a otra institución" },
        { const: "05", title: "05: Contra referido a otra institución" },
        { const: "06", title: "06: Derivado o referido a hospitalización domiciliaria" },
        { const: "07", title: "07: Derivado a servicio social" }
        
    ]
}