export const convertZero = (data: any): any => {
  const stack: { data: any; path: string[] }[] = [{ data, path: [] }];

  while (stack.length > 0) {
    const { data: currentData, path } = stack.pop()!;

    if (typeof currentData === "object" && currentData !== null) {
      if (Array.isArray(currentData)) {
        for (let i = 0; i < currentData.length; i++) {
          const newPath = [...path, i.toString()]; // Convertimos i a string
          stack.push({ data: currentData[i], path: newPath });
        }
      } else {
        for (const [key, value] of Object.entries(currentData)) {
          const newPath = [...path, key];
          if (value === "null") {
            currentData[key] = null;
          } else if (value === "0") {
            currentData[key] = 0;
          } else if (typeof value === "object" && value !== null) {
            stack.push({ data: value, path: newPath });
          }
        }
      }
    }
  }

  return data;
};
