import { useMemo, useState, useEffect } from "react";

import { Button, Grid } from "@mui/material";
import CustomTabs from "../../components/organisms/custom-tabs";
import { Group, History } from "@mui/icons-material";
import TransactionsPage from "../transactions";
import UsersPage from "../users";
import {
  initialTransactionsData,
  initialUsersData,
} from "../../utils/InitialData";
import { useStringifiedData } from "../../hooks/useStringifiedData";
import "../../App.css";
import "./styles.css";
import { convertZero } from "../../utils/functions";
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const Dashboard = ({ handleLogout, isValidated, setIsValidated }: any) => {
  const [tabsValue, setTabsValue] = useState(0);
  const [open, setOpen] = useState(false);

  const [transactionsData, setTransactionsData] = useState<any>(
    initialTransactionsData
  );
  const [usersData, setUsersData] = useState<any>(initialUsersData);

  type TempJsonByUser = {
    user: string;
    jsonFile: string;
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabsValue(newValue);
  };

  const downloadJsonFile = () => {
    setIsValidated(!isValidated);

    const completeJson = {
      ...transactionsData,
      ...(usersData.usuarios[0] &&
        Object.keys(usersData.usuarios[0]).length > 0 && { ...usersData }),
    };
    const modifiedData = convertZero(completeJson);

    const stringfifyedCompleteJson = JSON.stringify(modifiedData, null, 2);

    const blob = new Blob([stringfifyedCompleteJson], {
      type: "application/json",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "inicial.json";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const loadJSONFromLocalStorage = (storedData: string) => {
    //const storedData = localStorage.getItem('myStoredData');

    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        const { usuarios, ...filteredData } = parsedData;
        setTransactionsData(filteredData);
        setUsersData({
          ...(parsedData.usuarios[0] &&
            Object.keys(parsedData.usuarios[0]).length > 0 && { ...parsedData })
        });

        console.log('Data loaded from localStorage: ', parsedData);

        return parsedData;
      } catch (error) {
        console.error('error parsing JSON from localStorage:', error);
      }
    } else {
      console.log('No data found in localStorage.');
      return null;
    }
  };



  useEffect(() => {
    const storedData = localStorage.getItem(localStorage.getItem('loginUsername') ?? '');
    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        let jsonByUser: TempJsonByUser = {
          user: localStorage.getItem('loginUsername') ?? "",
          jsonFile: loadJSONFromLocalStorage(storedData)

        };
        console.log('Nombre con el que se carga ', localStorage.getItem('loginUsername'));


        //setTransactionsData(parsedData.transactionsData);
        //setUsersData(parsedData.usuarios);
        console.log('Data loaded from localStorage: ', parsedData, 'for the user', jsonByUser.user);

        const { usuarios, ...filteredData } = parsedData;

        console.log('Data para transacciones: ', filteredData);
        console.log('Data para usuarios: ', parsedData.usuarios);
        return parsedData && jsonByUser;
      } catch (error) {
        console.error('error parsing JSON from localStorage:', error);
      }
    } else {
      console.log('No data found in localStorage.');
      return null;
    }
  }, []);
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const AutohideSnackbar = () => {


  }
  const saveJSONToLocalStorage = () => {
    const completeJson = {
      ...transactionsData,
      ...(usersData.usuarios[0] &&
        Object.keys(usersData.usuarios[0]).length > 0 && { ...usersData }),
    };

    console.log(completeJson);
    const modifiedData = convertZero(completeJson);
    const stringfifyedCompleteJson = JSON.stringify(modifiedData, null, 2);

    localStorage.setItem('myStoredData', stringfifyedCompleteJson);
    const jsonByUser: TempJsonByUser = { user: localStorage.getItem('loginUsername') ?? '', jsonFile: stringfifyedCompleteJson };

    //localStorage.setItem(localStorage.getItem('loginUsername')?? '',  JSON.stringify(jsonByUser));
    console.log('nombre con el que se va a guardar: ', localStorage.getItem('loginUsername'));
    localStorage.setItem(localStorage.getItem('loginUsername') ?? '', stringfifyedCompleteJson);
    console.log('Data saved to localStorage');
    handleClick();

  }
  const stringifiedUsersData = useStringifiedData(usersData);
  const stringifiedTransactionsData = useStringifiedData(transactionsData);

  const mainTabsSchema = [
    {
      label: "Transacción",
      icon: <History />,
      iconPosition: "start",
      render: (
        <TransactionsPage
          data={transactionsData}
          setData={setTransactionsData}
          onChange={({ errors, data }: any) => setTransactionsData(data)}
          stringfiedData={stringifiedTransactionsData}
          initialData={initialTransactionsData}
        />
      ),
    },
    {
      icon: <Group />,
      iconPosition: "start",
      label: "Usuarios",
      render: (
        <UsersPage
          data={usersData}
          setData={setUsersData}
          onChange={({ errors, data }: any) => setUsersData(data)}
          stringfiedData={stringifiedUsersData}
          initialData={initialUsersData}
        />
      ),
    },
  ];

  return (
    <div className="app-container">
      <Grid container justifyContent={"center"} className="main-container">
        <div>
          <Button
            className={"generate-json-btn"}
            onClick={saveJSONToLocalStorage}
            color="success"
            variant="contained"
          >
            Guardar JSON
          </Button>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={open}
            autoHideDuration={4000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              variant="filled"
              sx={{ width: '100%' }}
            >
              JSON Guardado exitosamente!
            </Alert>
          </Snackbar>
          {/* <Button
            className={"generate-json-btn"}
            onClick={saveJSONToLocalStorage}
            color="success"
            variant="contained"
          >
            Cargar JSON
          </Button> */}
        </div>
        <CustomTabs
          name={"main"}
          tabsSchema={mainTabsSchema}
          tabsValue={tabsValue}
          handleChange={handleChange}
          ariaLabel="Sección Principal"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          tabsClassName="main-tabs"
        />
        <Button
          className={"generate-json-btn"}
          onClick={downloadJsonFile}
          color="success"
          variant="contained"
        >
          Generar JSON
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleLogout}
          className="logout-btn"
        >
          Cerrar sesión
        </Button>
      </Grid>
    </div>
  );
};

export default Dashboard;
