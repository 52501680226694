import { causaMotivoAtencion } from "../../commonSchemas/causaMotivationPrincipal";
import { codComplicacion } from "../../commonSchemas/codComplicacion";
import { codDiagnosticoCausaMuerte } from "../../commonSchemas/codDiagnosticoCausaMuerte";
import { codDiagnosticoPrincipal } from "../../commonSchemas/codDiagnosticoPrincipal";
import { codDiagnosticoPrincipalE } from "../../commonSchemas/codDiagnosticoPrincipalE";
import { codDiagnosticoRelacionado } from "../../commonSchemas/codDiagnosticoRelacionado";
import { codDiagnosticoPrincipalEN } from "../../commonSchemas/codDiagnosticoRelacionadoEN";
import { codPrestador } from "../../commonSchemas/codPrestador";
import { condicionDestinoUsuarioEgreso } from "../../commonSchemas/condicionDestinoUsuarioEgreso";
import { consecutivo } from "../../commonSchemas/consecutivo";
import { fechaEgreso } from "../../commonSchemas/fechaEgreso";
import { fechaInicioAtencion } from "../../commonSchemas/fechaInicioAtencion";
import { Property } from "../../commonSchemas/interfaces";
import { numeroAutorizacion } from "../../commonSchemas/numeroAutorizacion";
import { viaIngresoServicioSalud } from "../../commonSchemas/viaIngresoServicioSalud";

export const hospitalizacion: Property = {
  title: "Agregar hospitalización",
  type: "array",
  items: {
    type: "object",
    properties: {
      codPrestador,
      viaIngresoServicioSalud,
      fechaInicioAtencion,
      numAutorizacion: { ...numeroAutorizacion },
      causaMotivoAtencion,
      codDiagnosticoPrincipal,
      codDiagnosticoPrincipalE,
      codDiagnosticoRelacionadoE1: {
        ...codDiagnosticoRelacionado,
        title: "Código de Diagnóstico Relacionado E1",
      },
      codDiagnosticoRelacionadoE2: {
        ...codDiagnosticoRelacionado,
        title: "Código de Diagnóstico Relacionado E2",
      },
      codDiagnosticoRelacionadoE3: {
        ...codDiagnosticoRelacionado,
        title: "Código de Diagnóstico Relacionado E3",
      },
      codComplicacion,
      condicionDestinoUsuarioEgreso,
      codDiagnosticoCausaMuerte,
      fechaEgreso,
      consecutivo,
    },
  },
};
