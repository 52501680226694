import { Box, Tab, Tabs, Typography } from "@mui/material";

const CustomTabs = ({
  name,
  tabsSchema,
  tabsValue,
  handleChange,
  ariaLabel,
  variant,
  scrollButtons,
  allowScrollButtonsMobile,
  tabsClassName,
}: any) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Tabs
          className={tabsClassName}
          value={tabsValue}
          onChange={handleChange}
          aria-label={ariaLabel}
          variant={variant}
          scrollButtons={scrollButtons}
          allowScrollButtonsMobile={allowScrollButtonsMobile}
        >
          {tabsSchema.map((tab: any, index: number) => {
            const { icon, iconPosition, label } = tab;
            return (
              <Tab
                key={index}
                icon={icon}
                iconPosition={iconPosition}
                label={label}
              />
            );
          })}
        </Tabs>
      </Box>
      <>
        {tabsSchema.map((tab: any, index: number) => {
          const { render } = tab;

          return (
            <CustomTabPanel
              key={index}
              value={tabsValue}
              index={index}
              name={name}
            >
              {render}
            </CustomTabPanel>
          );
        })}
      </>
    </Box>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  name: string;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, name, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${name}-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default CustomTabs;
