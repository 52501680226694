import { codDiagnosticoCausaMuerte } from "../../commonSchemas/codDiagnosticoCausaMuerte";
import { codDiagnosticoPrincipal } from "../../commonSchemas/codDiagnosticoPrincipal";
import { codPrestador } from "../../commonSchemas/codPrestador";
import { codSexoBiologico } from "../../commonSchemas/codSexoBiologico";
import { condicionDestinoUsuarioEgreso } from "../../commonSchemas/condicionDestinoUsuarioEgreso";
import { consecutivo } from "../../commonSchemas/consecutivo";
import { edadGestacional } from "../../commonSchemas/edadGestacional";
import { fechaEgreso } from "../../commonSchemas/fechaEgreso";
import { fechaNacimientoRecienNacido } from "../../commonSchemas/fechaNacimientoRecienNacido";
import { Property } from "../../commonSchemas/interfaces";
import { numConsultasCPrenatal } from "../../commonSchemas/numConsultasCPrenatal";
import { numDocumentoIdentificacion } from "../../commonSchemas/numDocumentoIdentificacion";
import { peso } from "../../commonSchemas/peso";
import { tipoDocumentoIdentificacionRecienNacido } from "../../commonSchemas/tipoDocumentoIdentificacionRecienNacido";

export const recienNacidos: Property = {
  title: "Agregar registro de recién nacido",
  type: "array",
  items: {
    type: "object",
    properties: {
      codPrestador,
      tipoDocumentoIdentificacion: tipoDocumentoIdentificacionRecienNacido,
      numDocumentoIdentificacion,
      fechaNacimientoRecienNacido,
      edadGestacional,
      numConsultasCPrenatal,
      codSexoBiologico,
      peso,
      codDiagnosticoPrincipal,
      condicionDestinoUsuarioEgreso,
      codDiagnosticoCausaMuerte,
      fechaEgreso,
      consecutivo,
    },
  },
};
