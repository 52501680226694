import { causaMotivoAtencion } from "../../commonSchemas/causaMotivationPrincipal";
import { codDiagnosticoCausaMuerte } from "../../commonSchemas/codDiagnosticoCausaMuerte";
import { codDiagnosticoPrincipal } from "../../commonSchemas/codDiagnosticoPrincipal";
import { codDiagnosticoPrincipalE } from "../../commonSchemas/codDiagnosticoPrincipalE";
import { codDiagnosticoRelacionado } from "../../commonSchemas/codDiagnosticoRelacionado";
import { codDiagnosticoPrincipalEN } from "../../commonSchemas/codDiagnosticoRelacionadoEN";
import { codPrestador } from "../../commonSchemas/codPrestador";
import { condicionDestinoUsuarioEgreso } from "../../commonSchemas/condicionDestinoUsuarioEgreso";
import { consecutivo } from "../../commonSchemas/consecutivo";
import { fechaEgreso } from "../../commonSchemas/fechaEgreso";
import { fechaInicioAtencion } from "../../commonSchemas/fechaInicioAtencion";
import { Property } from "../../commonSchemas/interfaces";

export const urgencias: Property = {
  title: "Agregar urgencia",
  type: "array",
  items: {
    type: "object",
    properties: {
      codPrestador,
      fechaInicioAtencion,
      causaMotivoAtencion,
      codDiagnosticoPrincipal,
      codDiagnosticoPrincipalE,
      codDiagnosticoRelacionadoE1: {
        ...codDiagnosticoRelacionado,
        title: "Código de Diagnóstico Relacionado E1",
      },
      codDiagnosticoRelacionadoE2: {
        ...codDiagnosticoRelacionado,
        title: "Código de Diagnóstico Relacionado E2",
      },
      codDiagnosticoRelacionadoE3: {
        ...codDiagnosticoRelacionado,
        title: "Código de Diagnóstico Relacionado E3",
      },
      condicionDestinoUsuarioEgreso,
      codDiagnosticoCausaMuerte,
      fechaEgreso,
      consecutivo,
    },
  },
};
