import { causaMotivoAtencion } from '../../commonSchemas/causaMotivationPrincipal';
import { codConsulta } from '../../commonSchemas/codConsulta';
import { codDiagnosticoPrincipal } from '../../commonSchemas/codDiagnosticoPrincipal';
import { codDiagnosticoRelacionado } from '../../commonSchemas/codDiagnosticoRelacionado';
import { codPrestador } from '../../commonSchemas/codPrestador';
import { codServicio } from '../../commonSchemas/codServicio';
import { fechaInicioAtencion } from '../../commonSchemas/fechaInicioAtencion';
import { finalidadTecnologiaSalud } from '../../commonSchemas/finalidadTecnologiaSalud';
import { grupoServicios } from '../../commonSchemas/grupoServicios';
import { Property } from '../../commonSchemas/interfaces';
import { modalidadGrupoServicioTecSal } from '../../commonSchemas/modalidadGrupoServicioTecSal';
import { numeroAutorizacion } from '../../commonSchemas/numeroAutorizacion';
import { numFEVPagoModerador } from '../../commonSchemas/numFEVPagoModerador';
import { tipoDiagnosticoPrincipal } from '../../commonSchemas/tipoDiagnosticoPrincipal';
import { tipoDocumentoIdentificacion } from '../../commonSchemas/tipoDocumentoIdentificacion';
import { conceptoRecaudo } from '../../commonSchemas/conceptoRecaudo';
import { valorPagoModerador } from '../../commonSchemas/valorPagoModerador';
import { vrServicio } from '../../commonSchemas/vrServicio';
import { consecutivo } from '../../commonSchemas/consecutivo';
import { numDocumentoIdentificacion } from '../../commonSchemas/numDocumentoIdentificacion';
import { title } from 'process';

export const consultas: Property = {
  title: 'Agregar consulta',
  type: 'array',
  items: {
    type: 'object',
    properties: {
      codPrestador,
      fechaInicioAtencion,
      numAutorizacion: { ...numeroAutorizacion },
      codConsulta,
      modalidadGrupoServicioTecSal,
      grupoServicios,
      codServicio,
      finalidadTecnologiaSalud,
      causaMotivoAtencion,
      codDiagnosticoPrincipal,
      codDiagnosticoRelacionado1: {
        ...codDiagnosticoPrincipal,
        title: 'Código Diagnostico Relacionado 1',
      },
      codDiagnosticoRelacionado2: {
        ...codDiagnosticoPrincipal,
        title: 'Código Diagnostico Relacionado 2',
      },
      codDiagnosticoRelacionado3: {
        ...codDiagnosticoPrincipal,
        title: 'Código Diagnostico Relacionado 3',
      },
      tipoDiagnosticoPrincipal,
      tipoDocumentoIdentificacion,
      numDocumentoIdentificacion,
      vrServicio,
      conceptoRecaudo,
      valorPagoModerador,
      numFEVPagoModerador,
      consecutivo,
    },
  },
};
