import { Property } from "./interfaces";

export const tipoDocumentoIdentificacionRecienNacido: Property = {
  title: "Tipo de Documento de Identificación",
  type: "string",
  oneOf: [
    { const: "CN", title: "CN: Certificado de nacido vivo" },
    { const: "RC", title: "RC: Registro Civil" },
    { const: "MS", title: "MS: Menor sin identificar" },
  ],
};
