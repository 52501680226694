import CustomJsonForm from "../../components/organisms/custom-json-form";
import CustomPreview from "../../components/organisms/custom-preview";
import FormPageTemplate from "../../templates/main-template/form-page";
import schema from "./schema.json";
import uiSchema from "./uischema.json";
import { PageTypes } from "../../utils/Types";

const TransactionsPage = ({
  data,
  setData,
  onChange,
  stringfiedData,
  initialData,
}: PageTypes) => {
  const title = "Formulario de Transacciones";
  const previewTitle = "Vista Previa";

  return (
    <FormPageTemplate
      firstRender={
        <CustomJsonForm
          title={title}
          schema={schema}
          uiSchema={uiSchema}
          data={data}
          onChange={onChange}
        />
      }
      secondRender={
        <CustomPreview
          title={previewTitle}
          stringfiedData={stringfiedData}
          initialData={initialData}
          setData={setData}
        />
      }
    />
  );
};

export default TransactionsPage;
